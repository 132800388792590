import React from 'react';
import { motion } from 'framer-motion';
import { ArrowUpRight } from 'lucide-react';

interface ServiceCardProps {
  title: string;
  description: string;
  section: 'marketing' | 'outbound' | 'automation' | 'talent';
  isActive: boolean;
  onMouseEnter: () => void;
}

export function ServiceCard({ 
  title, 
  description, 
  isActive, 
  onMouseEnter 
}: ServiceCardProps) {
  return (
    <motion.div
      className="group relative p-8 rounded-2xl transition-all duration-300 cursor-pointer bg-background-dark/50 hover:bg-background-dark"
      onMouseEnter={onMouseEnter}
      initial={false}
      animate={{
        opacity: isActive ? 1 : 0.7,
        scale: isActive ? 1 : 0.98
      }}
    >
      <div className="flex items-start justify-between">
        <h3 className="text-xl font-bold text-white group-hover:text-primary transition-colors">
          {title}
        </h3>
        <ArrowUpRight className={`w-6 h-6 ${
          isActive ? 'text-primary' : 'text-white/50'
        } group-hover:text-primary transition-colors`} />
      </div>
      
      {isActive && (
        <motion.p
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          className="mt-4 text-white/70"
        >
          {description}
        </motion.p>
      )}
    </motion.div>
  );
}