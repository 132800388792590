export const services = [
  {
    title: 'Performance Marketing',
    description: 'Data-driven paid advertising that delivers predictable ROI. Our AI analyzes thousands of winning campaigns to build scalable acquisition systems.',
    section: 'marketing'
  },
  {
    title: 'Outbound',
    description: 'Generate high-quality leads that convert with AI-powered prospecting, multi-channel orchestration, and systematic outreach campaigns.',
    section: 'outbound'
  },
  {
    title: 'Build Internal AI Agents',
    description: 'Deploy intelligent agents to automate workflows, analyze data, and optimize business processes with state-of-the-art AI solutions.',
    section: 'automation'
  },
  {
    title: 'Build Your Team',
    description: 'Build high-performing teams that drive results. Our AI-powered recruiting combines deep market insights with precision matching for perfect-fit hires.',
    section: 'talent'
  }
] as const;