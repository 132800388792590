import React from 'react';
import { motion } from 'framer-motion';
import { Bot, Zap, Target, BarChart3 } from 'lucide-react';

const features = [
  {
    icon: Bot,
    title: 'AI-Powered Personalization',
    description: 'Our AI analyzes prospect data to craft personalized messages that resonate. Dynamic variables and smart templates ensure every message feels human.'
  },
  {
    icon: Zap,
    title: 'Smart Follow-ups',
    description: 'Automated sequences that adapt based on prospect behavior. Follow-up timing and messaging adjust automatically for optimal engagement.'
  },
  {
    icon: Target,
    title: 'Intent-Based Targeting',
    description: 'Identify and prioritize prospects showing buying signals. Our AI monitors digital behavior to trigger outreach at the perfect moment.'
  },
  {
    icon: BarChart3,
    title: 'Performance Analytics',
    description: 'Track engagement across all channels in real-time. A/B test messaging, analyze response rates, and optimize your outreach strategy.'
  }
];

export function AutomationFeatures() {
  return (
    <section className="py-20 bg-background-darker relative overflow-hidden">
      <div className="absolute inset-0 bg-gradient-to-b from-emerald-500/5 to-transparent opacity-50" />
      
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <div className="inline-block px-4 py-1 bg-emerald-500/10 rounded-full text-emerald-400 text-sm font-semibold mb-4">
            INTELLIGENT AUTOMATION
          </div>
          <h2 className="text-4xl font-bold text-white mb-6">
            AI-Powered Outreach That Converts
          </h2>
          <p className="text-lg text-white/80 max-w-2xl mx-auto">
            Let our AI handle the heavy lifting while maintaining the personal touch that drives results.
          </p>
        </div>
        
        <div className="grid md:grid-cols-2 gap-8">
          {features.map((feature, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              className="relative group"
            >
              <div className="absolute inset-0 bg-gradient-to-b from-white/5 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-2xl" />
              <div className="relative p-8 bg-background-dark rounded-2xl border border-white/10 group-hover:border-emerald-500/30 transition-colors">
                <div className="w-12 h-12 text-emerald-400 bg-white/5 rounded-xl flex items-center justify-center mb-4 group-hover:scale-110 transition-transform duration-300">
                  {React.createElement(feature.icon, {
                    className: "w-6 h-6",
                  })}
                </div>
                <h3 className="text-xl font-bold mb-3 text-white group-hover:text-emerald-400 transition-colors">
                  {feature.title}
                </h3>
                <p className="text-white/70 leading-relaxed">{feature.description}</p>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
}