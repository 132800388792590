import React from 'react';
import { motion } from 'framer-motion';
import { Zap, Target, Bell, Gauge } from 'lucide-react';

const triggers = [
  {
    icon: Target,
    title: 'Buying Intent Signals',
    description: 'Monitor technographic changes, hiring patterns, and digital footprints to identify companies actively looking for solutions like yours.',
    signals: ['Tech Stack Changes', 'Hiring Sprees', 'Funding Rounds', 'Website Updates']
  },
  {
    icon: Bell,
    title: 'Engagement Triggers',
    description: 'Automatically detect when prospects interact with your brand and trigger personalized outreach sequences.',
    signals: ['Website Visits', 'Content Downloads', 'Email Opens', 'Social Engagement']
  },
  {
    icon: Gauge,
    title: 'Growth Indicators',
    description: 'Track company growth signals that indicate perfect timing for your outreach.',
    signals: ['Revenue Growth', 'Team Expansion', 'New Locations', 'Product Launches']
  }
];

export function TriggerBasedSection() {
  return (
    <section className="py-20 bg-background-darker relative overflow-hidden">
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-gradient-to-b from-emerald-500/5 via-transparent to-transparent opacity-50" />
      </div>
      
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="inline-block px-4 py-1 bg-emerald-500/10 rounded-full text-emerald-400 text-sm font-semibold mb-4"
          >
            TRIGGER-BASED OUTBOUND
          </motion.div>
          
          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.1 }}
            className="text-4xl font-bold mb-6 text-white"
          >
            Perfect Timing, Perfect Message
          </motion.h2>
          
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.2 }}
            className="text-lg text-white/80 max-w-3xl mx-auto"
          >
            Stop cold outreach. Start conversations when prospects are most likely to engage.
            Our AI monitors thousands of signals to trigger outreach at the perfect moment.
          </motion.p>
        </div>

        <div className="grid lg:grid-cols-3 gap-8">
          {triggers.map((trigger, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.1 }}
              className="relative group"
            >
              <div className="absolute inset-0 bg-gradient-to-b from-white/5 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-2xl" />
              <div className="relative h-full p-8 bg-background-dark rounded-2xl border border-white/10 group-hover:border-emerald-500/30 transition-colors">
                <div className="w-12 h-12 text-emerald-400 bg-white/5 rounded-xl flex items-center justify-center mb-6 group-hover:scale-110 transition-transform duration-300">
                  {React.createElement(trigger.icon, {
                    className: "w-6 h-6"
                  })}
                </div>
                <h3 className="text-xl font-bold mb-4 text-white group-hover:text-emerald-400 transition-colors">
                  {trigger.title}
                </h3>
                <p className="text-white/70 mb-6">
                  {trigger.description}
                </p>
                <div className="space-y-3">
                  {trigger.signals.map((signal, idx) => (
                    <div 
                      key={idx}
                      className="flex items-center gap-2 text-sm text-white/60 group-hover:text-white/80 transition-colors"
                    >
                      <Zap className="w-4 h-4 text-emerald-400" />
                      {signal}
                    </div>
                  ))}
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
}