import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { CalendarDays, MessageCircle, UserCheck, Calendar } from 'lucide-react';

const steps = [
  {
    icon: CalendarDays,
    title: 'Book a Quick Chat',
    description: 'Share your growth goals and challenges with our team'
  },
  {
    icon: MessageCircle,
    title: 'Strategy Discussion',
    description: 'Get a customized plan based on your unique needs'
  },
  {
    icon: UserCheck,
    title: 'Meet Your Team',
    description: 'Connect with your dedicated growth specialists'
  },
  {
    icon: Calendar,
    title: 'Start Growing',
    description: 'Launch your first campaigns within days'
  }
];

export function CalendarSection() {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://asset-tidycal.b-cdn.net/js/embed.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      if (script.parentNode) {
        script.parentNode.removeChild(script);
      }
    };
  }, []);

  return (
    <section className="calendar-section py-20 bg-background-darker relative overflow-hidden">
      <div className="absolute inset-0 bg-gradient-to-b from-background-dark to-transparent opacity-50" />
      
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <div className="inline-block px-4 py-1 bg-primary/10 rounded-full text-primary-light text-sm font-semibold mb-4">
            SEE IF YOU'RE A FIT
          </div>
          <h2 className="text-4xl font-bold text-white mb-4">Ready to Scale Your Growth?</h2>
          <p className="text-lg text-white/80 max-w-2xl mx-auto">
            Book a call with our team to discuss your growth goals
          </p>
        </div>

        <div className="grid lg:grid-cols-2 gap-12">
          {/* Calendar Embed */}
          <div className="bg-white rounded-2xl overflow-hidden shadow-xl">
            <div 
              className="tidycal-embed" 
              data-path="1y56wy3/15-minute-meeting"
              style={{ 
                width: '100%',
                height: '800px',
                border: 'none',
                overflow: 'auto'
              }}
            ></div>
          </div>

          {/* Process Steps */}
          <div className="space-y-6">
            {steps.map((step, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, x: 50 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
                className="flex items-start gap-4 bg-background-dark/80 backdrop-blur-sm p-6 rounded-xl border border-white/10"
              >
                <div className="w-12 h-12 text-primary bg-white/5 rounded-xl flex items-center justify-center flex-shrink-0">
                  {React.createElement(step.icon, {
                    className: "w-6 h-6",
                  })}
                </div>
                <div>
                  <h3 className="text-lg font-semibold text-white mb-1">{step.title}</h3>
                  <p className="text-white/70">{step.description}</p>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}