import React from 'react';
import { motion } from 'framer-motion';
import { Layout } from '../components/Layout';
import { Target, Users, Lightbulb, TrendingUp } from 'lucide-react';
import { Button } from '../components/Button';

const values = [
  {
    icon: Target,
    title: 'Data-Driven Excellence',
    description: 'Every strategy we implement is backed by robust data and analytics.'
  },
  {
    icon: Users,
    title: 'Client Partnership',
    description: 'We work as an extension of your team, fully aligned with your goals.'
  },
  {
    icon: Lightbulb,
    title: 'Innovation First',
    description: 'Constantly pushing boundaries with cutting-edge AI and technology.'
  },
  {
    icon: TrendingUp,
    title: 'Results Focused',
    description: 'Committed to delivering measurable growth and ROI.'
  }
];

export default function AboutPage() {
  return (
    <Layout>
      {/* Hero Section */}
      <section className="relative min-h-[60vh] flex items-center bg-background-darker overflow-hidden">
        <div className="absolute inset-0">
          <div className="absolute inset-0 bg-gradient-radial from-primary/5 via-transparent to-transparent opacity-80" />
        </div>
        
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="max-w-3xl"
          >
            <h1 className="text-6xl font-bold mb-6 text-white">
              Driving Growth Through
              <span className="text-primary"> Innovation</span>
            </h1>
            <p className="text-xl mb-8 text-white/80">
              We're a team of growth experts, engineers, and creative minds dedicated to 
              scaling businesses through data-driven strategies and cutting-edge technology.
            </p>
          </motion.div>
        </div>
      </section>

      {/* Mission Section */}
      <section className="py-20 bg-background-dark">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-3xl mx-auto text-center">
            <h2 className="text-3xl font-bold mb-6 text-white">Our Mission</h2>
            <p className="text-xl text-white/80">
              To empower businesses with the tools, strategies, and talent they need to 
              achieve unprecedented growth in the digital age. We combine human expertise 
              with AI innovation to deliver exceptional results.
            </p>
          </div>
        </div>
      </section>

      {/* Values Section */}
      <section className="py-20 bg-background-darker">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold mb-4 text-white">Our Core Values</h2>
          </div>
          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
            {values.map((value, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                className="p-6 bg-background-dark rounded-2xl border border-primary/10 hover:border-primary/30 transition-all duration-300"
              >
                <div className="w-12 h-12 bg-primary/10 rounded-xl flex items-center justify-center mb-4">
                  {React.createElement(value.icon, {
                    className: 'w-6 h-6 text-primary',
                  })}
                </div>
                <h3 className="text-xl font-bold mb-2 text-white">{value.title}</h3>
                <p className="text-white/70">{value.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-20 bg-background-dark">
        <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-4xl font-bold mb-4 text-white">Ready to Scale Your Business?</h2>
          <p className="text-xl mb-8 text-white/80">
            Let's discuss how we can help you achieve your growth goals.
          </p>
          <Button variant="primary" size="lg">
            Get Started
          </Button>
        </div>
      </section>
    </Layout>
  );
}