import React from 'react';
import { motion } from 'framer-motion';
import { FAQ } from './FAQ';
import { faqData } from './faqData';

export function FAQSection() {
  return (
    <section className="py-20 bg-background-darker">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <div className="inline-block px-4 py-1 bg-primary/10 rounded-full text-primary-light text-sm font-semibold mb-4">
            FAQs
          </div>
          <h2 className="text-4xl font-bold mb-4 text-white">
            Still Have Questions? We've Got <span className="text-primary">Answers</span>
          </h2>
        </div>
        <FAQ data={faqData} />
      </div>
    </section>
  );
}