import React from 'react';
import { Link, useLocation } from 'react-router-dom';

interface NavLinkProps {
  href: string;
  children: React.ReactNode;
}

export function NavLink({ href, children }: NavLinkProps) {
  const location = useLocation();
  const isActive = location.pathname === href;
  
  return (
    <Link 
      to={href}
      className={`text-white/70 hover:text-white transition-colors duration-200 text-sm font-medium ${
        isActive ? 'text-white' : ''
      }`}
    >
      {children}
    </Link>
  );
}