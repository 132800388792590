import React from 'react';
import { Layout } from '../../../components/Layout';
import { PaidHero } from './components/PaidHero';
import { AdPlatforms } from './components/AdPlatforms';
import { ProcessSection } from './components/ProcessSection';
import { CaseStudiesSection } from './components/CaseStudies';
import { CalendarSection } from './components/CalendarSection';
import { FAQSection } from './components/FAQ';
import { CTASection } from '../../../components/CTASection';

export default function PaidAdvertisingPage() {
  return (
    <Layout>
      <PaidHero />
      <AdPlatforms />
      <ProcessSection />
      <CaseStudiesSection />
      <CalendarSection />
      <FAQSection />
      <CTASection />
    </Layout>
  );
}