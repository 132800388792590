import React from 'react';
import { motion } from 'framer-motion';
import { Facebook, Youtube, Linkedin, Search, Radio, Megaphone } from 'lucide-react';

const platforms = [
  {
    icon: Facebook,
    name: 'Meta Ads',
    description: 'Precise targeting across Facebook & Instagram',
    color: 'text-blue-500'
  },
  {
    icon: Search,
    name: 'Google Ads',
    description: 'Search, Display & Performance Max campaigns',
    color: 'text-green-500'
  },
  {
    icon: Radio,
    name: 'TikTok Ads',
    description: 'Engaging Gen Z & Millennial audiences',
    color: 'text-pink-500'
  },
  {
    icon: Linkedin,
    name: 'LinkedIn Ads',
    description: 'B2B targeting & account-based marketing',
    color: 'text-blue-400'
  },
  {
    icon: Youtube,
    name: 'YouTube Ads',
    description: 'Video campaigns & brand awareness',
    color: 'text-red-500'
  },
  {
    icon: Megaphone,
    name: 'Display Ads',
    description: 'Programmatic & retargeting campaigns',
    color: 'text-purple-500'
  }
];

export function AdPlatforms() {
  return (
    <section className="py-20 bg-background-dark relative overflow-hidden">
      <div className="absolute inset-0 bg-gradient-to-b from-background-darker to-transparent opacity-50" />
      
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-white mb-4">Platforms We Master</h2>
          <p className="text-white/70 text-lg">Comprehensive multi-channel advertising expertise</p>
        </div>
        
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
          {platforms.map((platform, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              className="relative group h-full"
            >
              <div className="absolute inset-0 bg-gradient-to-b from-white/5 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-2xl" />
              <div className="relative h-full p-8 bg-background-darker rounded-2xl border border-white/10 group-hover:border-blue-500/30 transition-colors">
                <div className={`w-12 h-12 ${platform.color} bg-white/5 rounded-xl flex items-center justify-center mb-4 group-hover:scale-110 transition-transform duration-300`}>
                  {React.createElement(platform.icon, {
                    className: "w-6 h-6",
                  })}
                </div>
                <h3 className="text-xl font-bold mb-2 text-white group-hover:text-blue-400 transition-colors">
                  {platform.name}
                </h3>
                <p className="text-white/70">{platform.description}</p>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
}