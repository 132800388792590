import React from 'react';
import { motion } from 'framer-motion';
import { FAQ } from '../../../../../components/FAQ/FAQ';
import { faqData } from './faqData';

export function FAQSection() {
  return (
    <section className="py-20 bg-background-darker">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="inline-block px-4 py-1 bg-blue-500/10 rounded-full text-blue-400 text-sm font-semibold mb-4"
          >
            Common Questions
          </motion.div>
          
          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.1 }}
            className="text-4xl font-bold mb-4 text-white"
          >
            Everything You Need to Know About<br />
            <span className="text-blue-400">Performance Marketing</span>
          </motion.h2>
          
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.2 }}
            className="text-lg text-white/80 max-w-3xl mx-auto"
          >
            Get answers to the most common questions about our paid advertising services, 
            measurement approach, and performance guarantees.
          </motion.p>
        </div>

        <FAQ data={faqData} />
      </div>
    </section>
  );
}