import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Menu, X } from 'lucide-react';
import { Logo } from './Logo';
import { NavLink } from './NavLink';
import { Link } from 'react-router-dom';
import { CalendarButton } from '../CalendarButton';

export function Navbar() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 20);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <>
      <motion.nav
        className={`fixed w-full z-50 transition-all duration-300 ${
          isScrolled ? 'bg-background-dark/80 backdrop-blur-lg' : 'bg-transparent'
        }`}
        initial={{ y: -100 }}
        animate={{ y: 0 }}
        transition={{ duration: 0.6 }}
      >
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-20">
            <Link to="/" className="hover:opacity-80 transition-opacity">
              <Logo />
            </Link>
            
            {/* Desktop Navigation */}
            <div className="hidden md:flex items-center gap-8">
              <NavLink href="/paid-advertising">Paid Advertising</NavLink>
              <NavLink href="/hire-team">Hire Your Team</NavLink>
              <NavLink href="/inspo">Outbound Engine</NavLink>
              <CalendarButton 
                variant="primary"
                size="sm"
                className="bg-amber-400 hover:bg-amber-500 text-black ml-4"
              >
                Let's Talk
              </CalendarButton>
            </div>

            {/* Mobile Menu Button */}
            <button
              className="md:hidden text-white p-2"
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            >
              {isMobileMenuOpen ? <X className="w-6 h-6" /> : <Menu className="w-6 h-6" />}
            </button>
          </div>

          {/* Mobile Navigation */}
          {isMobileMenuOpen && (
            <motion.div 
              className="md:hidden py-4"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.2 }}
            >
              <div className="flex flex-col gap-4">
                <NavLink href="/paid-advertising">Paid Advertising</NavLink>
                <NavLink href="/hire-team">Hire Your Team</NavLink>
                <NavLink href="/inspo">Outbound Engine</NavLink>
                <CalendarButton 
                  variant="primary"
                  size="sm"
                  className="bg-amber-400 hover:bg-amber-500 text-black w-full"
                >
                  Let's Talk
                </CalendarButton>
              </div>
            </motion.div>
          )}
        </div>
      </motion.nav>
    </>
  );
}