import React from 'react';
import { motion } from 'framer-motion';

export function CompanyLogos() {
  const companies = [
    {
      name: 'Meta',
      logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/ab/Meta-Logo.png/1200px-Meta-Logo.png',
      class: 'h-10 lg:h-12' // 20% larger
    },
    {
      name: 'OpenAI',
      logo: 'https://upload.wikimedia.org/wikipedia/commons/4/4d/OpenAI_Logo.svg',
      class: 'h-8 lg:h-10'
    },
    {
      name: 'DeepMind',
      logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/d/dc/Google_DeepMind_logo.svg/2560px-Google_DeepMind_logo.svg.png',
      class: 'h-8 lg:h-10'
    },
    {
      name: 'Amazon',
      logo: 'https://pngimg.com/uploads/amazon/amazon_PNG8.png',
      class: 'h-8 lg:h-10'
    },
    {
      name: 'Netflix',
      logo: 'https://upload.wikimedia.org/wikipedia/commons/7/7a/Logonetflix.png',
      class: 'h-8 lg:h-10'
    }
  ];

  return (
    <section className="py-16 bg-background-darker relative overflow-hidden">
      <div className="absolute inset-0 bg-gradient-to-b from-background-dark/50 to-transparent" />
      
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
        <motion.p 
          className="text-center text-lg text-amber-400 mb-12"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
        >
          We work with almost 100 startups using AI from seed to late stage funding with talent from
        </motion.p>

        <div className="flex flex-wrap justify-center items-center gap-x-16 gap-y-8">
          {companies.map((company, index) => (
            <motion.div
              key={company.name}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.1 }}
              className="flex items-center justify-center"
            >
              <img 
                src={company.logo} 
                alt={company.name} 
                className={`${company.class} w-auto opacity-90 hover:opacity-100 transition-opacity brightness-0 invert`}
              />
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
}