import React from 'react';
import { FAQItem } from './FAQItem';

interface FAQProps {
  data: Array<{
    question: string;
    answer: string;
  }>;
}

export function FAQ({ data }: FAQProps) {
  return (
    <div className="max-w-3xl mx-auto">
      <div className="space-y-4">
        {data.map((faq, index) => (
          <FAQItem key={index} {...faq} />
        ))}
      </div>
    </div>
  );
}