import React from 'react';
import { motion } from 'framer-motion';

const testimonials = [
  {
    quote: "aiGrowthGroup helped us scale our AI team from 5 to 50 engineers in just 3 months. Their deep understanding of technical talent was crucial for our LLM development.",
    role: "VP of Engineering, Fortune 500 Tech Company",
    timeline: "Full AI Team Built in 3 Months",
    color: "text-amber-400"
  },
  {
    quote: "After struggling with traditional recruiters, aiGrowthGroup helped us build our entire ML infrastructure team. Their candidates' quality was exceptional.",
    role: "CTO, AI Unicorn",
    timeline: "12 Senior ML Engineers Hired in 6 Weeks",
    color: "text-amber-400"
  },
  {
    quote: "Their network of PhD researchers from top AI labs was impressive. We built our entire research division through their platform.",
    role: "Director of AI Research, OpenAI Competitor",
    timeline: "Research Team of 8 PhD Scientists Hired in 8 Weeks",
    color: "text-amber-400"
  },
  {
    quote: "aiGrowthGroup found us specialized talent in multimodal AI that we couldn't source anywhere else. Their technical screening process is unmatched.",
    role: "Head of AI, Series D Startup",
    timeline: "5 Senior AI Architects Hired in 3 Weeks",
    color: "text-amber-400"
  }
];

export function SuccessStories() {
  return (
    <section className="py-20 bg-background-darker relative overflow-hidden">
      <div className="absolute inset-0 bg-gradient-to-b from-background-dark/50 to-transparent opacity-70" />
      
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="inline-block px-4 py-1 bg-amber-500/10 rounded-full text-amber-400 text-sm font-semibold mb-4"
          >
            Enterprise Success Stories
          </motion.div>
          
          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.1 }}
            className="text-4xl font-bold text-white mb-6"
          >
            Trusted by Industry Leaders
          </motion.h2>
          
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.2 }}
            className="text-lg text-white/80 max-w-3xl mx-auto"
          >
            From Fortune 500 enterprises to cutting-edge AI startups, we help organizations build world-class technical teams that drive innovation.
          </motion.p>
        </div>

        <div className="grid md:grid-cols-2 gap-6">
          {testimonials.map((testimonial, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.1 }}
              className="bg-background-dark/50 backdrop-blur-sm p-8 rounded-2xl border border-white/5 hover:border-amber-500/20 transition-colors"
            >
              <p className="text-white/90 text-lg italic leading-relaxed mb-8">
                "{testimonial.quote}"
              </p>
              <div>
                <p className={`${testimonial.color} font-semibold text-lg mb-1`}>
                  {testimonial.role}
                </p>
                <p className="text-white/60">
                  {testimonial.timeline}
                </p>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
}