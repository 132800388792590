import React from 'react';
import { motion } from 'framer-motion';

export function ValueProposition() {
  const features = [
    {
      title: 'Technical Precision',
      description: 'Whether you need LLM researchers, MLOps experts, or AI infrastructure architects, we understand your exact needs.'
    },
    {
      title: 'Deep Industry Insight',
      description: 'Active tracking of 26,000+ AI-focused companies and conferences like NeurIPS.'
    },
    {
      title: 'Growth Stage Aligned',
      description: 'From stealth-mode technical founders to Series C+ AI leadership teams, we\'ve scaled companies at every stage.'
    }
  ];

  return (
    <section className="py-20 bg-background-dark">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid lg:grid-cols-2 gap-12 items-center">
          <div>
            <img 
              src="https://images.unsplash.com/photo-1543269664-56d93c1b41a6?auto=format&fit=crop&q=80&w=1000"
              alt="AI Recruitment"
              className="rounded-2xl"
            />
          </div>
          
          <div>
            <div className="inline-block px-4 py-1 bg-amber-500/10 rounded-full text-amber-400 text-sm font-semibold mb-4">
              Proven Expertise, Unparalleled Insight
            </div>
            <h2 className="text-4xl font-bold text-white mb-6">
              Why Tech Leaders Choose Us
            </h2>
            <p className="text-lg text-white/80 mb-8">
              Skip the months-long search for elite AI talent. While others post jobs and wait, we tap into exclusive networks built over 7 years in AI recruiting. Our deep technical expertise ensures you get candidates who've solved challenges like yours before.
            </p>
            
            <div className="space-y-6">
              {features.map((feature, index) => (
                <motion.div 
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: index * 0.1 }}
                  className="bg-background-darker/50 p-6 rounded-xl"
                >
                  <h3 className="text-lg font-semibold text-white mb-2">{feature.title}</h3>
                  <p className="text-white/70">{feature.description}</p>
                </motion.div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}