import React from 'react';
import { motion } from 'framer-motion';
import { Button } from '../../../../components/Button';
import { ArrowRight, Mail, Linkedin, Phone } from 'lucide-react';

export function CreativeHero() {
  const scrollToSignalSection = () => {
    const signalSection = document.querySelector('#signal-based-section');
    if (signalSection) {
      signalSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const scrollToCalendar = () => {
    const calendarSection = document.querySelector('#calendar-section');
    if (calendarSection) {
      calendarSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <section className="relative min-h-[85vh] bg-background-darker overflow-hidden">
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-gradient-radial from-emerald-500/5 via-transparent to-transparent opacity-80" />
      </div>

      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-32">
        <div className="grid lg:grid-cols-2 gap-12 items-center">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            <div className="inline-block px-4 py-1 bg-emerald-500/10 rounded-full text-emerald-400 text-sm font-semibold mb-6">
              MULTI-CHANNEL OUTBOUND
            </div>
            <h1 className="text-5xl font-bold mb-6 text-white leading-tight">
              Turn Cold Outreach Into Your Most Predictable 
              <span className="text-emerald-400"> Revenue Engine</span>
            </h1>
            <p className="text-xl mb-8 text-white/80">
              Orchestrate personalized outreach at scale across email, LinkedIn, and calls. 
              Our AI helps you find, engage, and convert your ideal customers with precision.
            </p>
            <div className="flex flex-col sm:flex-row gap-4">
              <Button 
                variant="primary" 
                size="lg"
                className="bg-emerald-500 hover:bg-emerald-600 group"
                onClick={scrollToCalendar}
              >
                Book Call
                <ArrowRight className="ml-2 w-5 h-5 group-hover:translate-x-1 transition-transform" />
              </Button>
              <Button 
                variant="secondary" 
                size="lg"
                onClick={scrollToSignalSection}
              >
                Learn More
              </Button>
            </div>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
            className="relative"
          >
            <div className="absolute inset-0 bg-gradient-to-b from-emerald-500/10 via-transparent to-transparent rounded-2xl" />
            <div className="relative bg-background-dark p-8 rounded-2xl border border-emerald-500/20">
              <div className="space-y-6">
                <div className="flex items-center gap-4 p-4 bg-background-darker rounded-xl">
                  <Mail className="w-8 h-8 text-emerald-400" />
                  <div>
                    <div className="text-white font-semibold">Smart Email Sequences</div>
                    <div className="text-white/60 text-sm">45% average response rate</div>
                  </div>
                </div>
                <div className="flex items-center gap-4 p-4 bg-background-darker rounded-xl">
                  <Linkedin className="w-8 h-8 text-emerald-400" />
                  <div>
                    <div className="text-white font-semibold">LinkedIn Automation</div>
                    <div className="text-white/60 text-sm">40% acceptance rate, 10% reply rates</div>
                  </div>
                </div>
                <div className="flex items-center gap-4 p-4 bg-background-darker rounded-xl">
                  <Phone className="w-8 h-8 text-emerald-400" />
                  <div>
                    <div className="text-white font-semibold">Call Scheduling</div>
                    <div className="text-white/60 text-sm">Automated follow-ups</div>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
}