import React from 'react';
import { motion } from 'framer-motion';
import { Brain, Code, Database, Network, Cpu, Cloud } from 'lucide-react';

const expertiseAreas = [
  {
    icon: Brain,
    title: 'Machine Learning',
    positions: [
      'ML Engineers',
      'Research Scientists',
      'ML Platform Engineers',
      'ML Product Managers'
    ],
    color: 'text-purple-400'
  },
  {
    icon: Code,
    title: 'AI Engineering',
    positions: [
      'AI Software Engineers',
      'NLP Engineers',
      'Computer Vision Engineers',
      'AI Full-Stack Developers'
    ],
    color: 'text-blue-400'
  },
  {
    icon: Database,
    title: 'Data Science',
    positions: [
      'Data Scientists',
      'Data Engineers',
      'Analytics Engineers',
      'Data Architects'
    ],
    color: 'text-green-400'
  },
  {
    icon: Network,
    title: 'MLOps',
    positions: [
      'MLOps Engineers',
      'ML Infrastructure Engineers',
      'ML Platform Architects',
      'DevOps for AI'
    ],
    color: 'text-amber-400'
  },
  {
    icon: Cpu,
    title: 'AI Leadership',
    positions: [
      'AI Technical Leaders',
      'AI Product Leaders',
      'ML Engineering Managers',
      'AI Program Directors'
    ],
    color: 'text-red-400'
  },
  {
    icon: Cloud,
    title: 'AI Infrastructure',
    positions: [
      'AI Infrastructure Engineers',
      'GPU Cluster Engineers',
      'AI Systems Architects',
      'AI Platform Engineers'
    ],
    color: 'text-indigo-400'
  }
];

export function ExpertiseSection() {
  return (
    <section className="py-20 bg-background-dark relative overflow-hidden">
      <div className="absolute inset-0 bg-gradient-to-b from-background-darker to-transparent opacity-50" />
      
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <div className="inline-block px-4 py-1 bg-emerald-500/10 rounded-full text-emerald-400 text-sm font-semibold mb-4">
            Your AI Hiring Advantage
          </div>
          <h2 className="text-3xl font-bold text-white mb-4">Elite AI Talent Network</h2>
          <p className="text-white/70 text-lg max-w-3xl mx-auto">
            50,000+ pre-vetted professionals from top institutions like MIT, Stanford, and leading AI companies. 
            90% offer acceptance rate and unmatched placement success.
          </p>
        </div>
        
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
          {expertiseAreas.map((area, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              className="relative group h-full"
            >
              <div className="absolute inset-0 bg-gradient-to-b from-white/5 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-2xl" />
              <div className="relative h-full p-8 bg-background-darker rounded-2xl border border-white/10 group-hover:border-emerald-500/30 transition-colors">
                <div className={`w-12 h-12 ${area.color} bg-white/5 rounded-xl flex items-center justify-center mb-4 group-hover:scale-110 transition-transform duration-300`}>
                  {React.createElement(area.icon, {
                    className: "w-6 h-6",
                  })}
                </div>
                <h3 className="text-xl font-bold mb-4 text-white group-hover:text-emerald-400 transition-colors">
                  {area.title}
                </h3>
                <ul className="space-y-2">
                  {area.positions.map((position, idx) => (
                    <li key={idx} className="text-white/70 hover:text-white transition-colors flex items-center gap-2">
                      <div className="w-1.5 h-1.5 bg-emerald-400 rounded-full" />
                      {position}
                    </li>
                  ))}
                </ul>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
}