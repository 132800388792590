import React from 'react';
import { motion } from 'framer-motion';
import { Button } from '../../../components/Button';
import { RotatingText } from '../../../components/RotatingText';

const rotatingWords = [
  'ML Engineers',
  'AI Researchers',
  'Data Scientists',
  'MLOps Experts',
  'AI Architects'
];

export function HireTeamHero() {
  const scrollToCalendar = () => {
    const calendarSection = document.querySelector('#calendar-section');
    if (calendarSection) {
      calendarSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <section className="relative min-h-[85vh] bg-background-darker overflow-hidden">
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-gradient-radial from-emerald-500/5 via-transparent to-transparent opacity-80" />
        <div className="absolute inset-0 bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-emerald-500/10 via-transparent to-transparent blur-xl" />
      </div>

      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-32">
        <div className="max-w-4xl mx-auto text-center">
          <motion.div
            className="text-6xl font-bold mb-8 text-white leading-tight"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            <h1>
              Build Your AI Advantage with<br />
              <span className="text-emerald-400">Elite Technical Talent</span>
            </h1>
            <div className="mt-4 flex justify-center">
              <RotatingText words={rotatingWords} />
            </div>
          </motion.div>

          <motion.p
            className="text-xl mb-12 text-white/80"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
          >
            In the AI talent war, your edge depends on who builds your technology. Our network of 50,000+ pre-vetted engineers from MIT, Stanford, and Google brings you the specialized talent you need—in weeks, not months.
          </motion.p>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.4 }}
            className="flex flex-col sm:flex-row gap-4 justify-center"
          >
            <Button 
              variant="primary" 
              size="lg"
              className="bg-emerald-500 hover:bg-emerald-600 text-white font-semibold transform hover:scale-105 transition-all duration-300 shadow-[0_0_30px_rgba(16,185,129,0.3)] hover:shadow-[0_0_50px_rgba(16,185,129,0.5)]"
              onClick={scrollToCalendar}
            >
              Start Hiring Now
            </Button>
          </motion.div>

          <motion.p
            className="mt-8 text-lg text-white/60"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.6 }}
          >
            90% offer acceptance rate. No more failed searches or mis-hired engineers.
          </motion.p>
        </div>
      </div>
    </section>
  );
}