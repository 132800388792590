import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { ServiceCard } from './ServiceCard';
import { services } from './serviceData';

export function ServicesSection() {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <section className="py-20 bg-background-darker relative overflow-hidden">
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-gradient-to-b from-primary/5 via-transparent to-transparent opacity-50" />
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
        <div className="text-center mb-16">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
          >
            <h2 className="text-4xl font-bold mb-4 text-white">
              Predictable Systems for
              <br />
              <span className="text-primary">Exceptional Growth</span>
            </h2>
            <p className="text-xl text-white/80 mt-4 max-w-2xl mx-auto">
              Data-driven strategies that consistently deliver results. No guesswork, just proven methods.
            </p>
          </motion.div>
        </div>

        <div className="space-y-6">
          {services.map((service, index) => (
            <ServiceCard
              key={index}
              {...service}
              isActive={activeIndex === index}
              onMouseEnter={() => setActiveIndex(index)}
            />
          ))}
        </div>
      </div>
    </section>
  );
}