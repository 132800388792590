import { Target, Rocket, BarChart3, TrendingUp } from 'lucide-react';
import { LucideIcon } from 'lucide-react';

interface ProcessStep {
  icon: LucideIcon;
  title: string;
  description: string;
}

export const steps: ProcessStep[] = [
  {
    icon: Target,
    title: 'Analyze',
    description: 'Deep dive into your data, market position, and growth opportunities'
  },
  {
    icon: Rocket,
    title: 'Execute',
    description: 'Deploy proven strategies across channels with precision and speed'
  },
  {
    icon: BarChart3,
    title: 'Optimize',
    description: 'Continuously refine and improve based on real-time performance data'
  },
  {
    icon: TrendingUp,
    title: 'Scale',
    description: 'Systematically expand successful campaigns for maximum impact'
  }
];