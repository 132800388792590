import React from 'react';
import { Layout } from '../components/Layout';
import { CreativeHero } from './services/outbound/components/CreativeHero';
import { ProspectingSection } from './services/outbound/components/ProspectingSection';
import { AutomationFeatures } from './services/outbound/components/AutomationFeatures';
import { TechStackShowcase } from './services/outbound/components/TechStackShowcase';
import { TriggerBasedSection } from './services/outbound/components/TriggerBasedSection';
import { CalendarSection } from './services/outbound/components/CalendarSection';
import { CTASection } from '../components/CTASection';

export default function InspoPage() {
  return (
    <Layout>
      <CreativeHero />
      <ProspectingSection />
      <AutomationFeatures />
      <TechStackShowcase />
      <TriggerBasedSection />
      <CalendarSection />
      <CTASection />
    </Layout>
  );
}