import React from 'react';
import { motion } from 'framer-motion';

export function TalentNetwork() {
  const features = [
    {
      title: 'Elite Educational Backgrounds',
      description: 'Top AI talent from MIT, Stanford, Carnegie Mellon, UC Berkeley, and Caltech, with deep expertise in cutting-edge ML research.'
    },
    {
      title: 'Big Tech Veterans',
      description: 'Senior engineers from Google AI, Meta, NVIDIA, and Amazon who\'ve scaled AI systems impacting millions.'
    },
    {
      title: 'Startup Success Stories',
      description: 'Early engineers from OpenAI, DataRobot, and Scale AI who\'ve built AI products from zero to scale.'
    }
  ];

  return (
    <section className="py-20 bg-background-darker">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid lg:grid-cols-2 gap-12 items-center">
          <div>
            <div className="inline-block px-4 py-1 bg-amber-500/10 rounded-full text-amber-400 text-sm font-semibold mb-4">
              Hire AI Engineers Others Can't Reach
            </div>
            <h2 className="text-4xl font-bold text-white mb-6">
              50,000+ AI Engineers in Our Network
            </h2>
            <p className="text-lg text-white/80 mb-8">
              While others compete for public profiles, tap into our exclusive network of 50,000+ pre-vetted AI specialists who've built and scaled successful AI products.
            </p>
            
            <div className="space-y-6">
              {features.map((feature, index) => (
                <motion.div 
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: index * 0.1 }}
                  className="bg-white/5 p-6 rounded-xl"
                >
                  <h3 className="text-lg font-semibold text-white mb-2">{feature.title}</h3>
                  <p className="text-white/70">{feature.description}</p>
                </motion.div>
              ))}
            </div>
          </div>

          <div>
            <img 
              src="https://images.unsplash.com/photo-1573496799652-408c2ac9fe98?auto=format&fit=crop&q=80&w=1000"
              alt="AI Engineer"
              className="rounded-2xl"
            />
          </div>
        </div>
      </div>
    </section>
  );
}