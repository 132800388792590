export const faqData = [
  {
    question: "What services do you offer?",
    answer: "We provide comprehensive growth solutions including performance marketing (Meta, Google, TikTok, LinkedIn ads), outbound lead generation, AI agent development for business process automation, and elite talent recruitment. Our focus is on building systematic, scalable growth engines across all channels."
  },
  {
    question: "How do you measure success?",
    answer: "We track key metrics specific to each service: ROAS and CAC for paid advertising, pipeline growth for outbound, process efficiency gains for AI implementations, and offer acceptance/retention rates for recruiting. We typically achieve 3-4x ROAS, 60% CAC reduction, and 92% offer acceptance rates."
  },
  {
    question: "What makes your approach different?",
    answer: "We focus on building systematic, data-driven growth engines rather than one-off campaigns. Our AI-powered tools analyze thousands of data points to optimize performance, while our multi-channel approach ensures we're reaching prospects at the right time with the right message."
  },
  {
    question: "How long until we see results?",
    answer: "Most clients see initial results within 30-60 days. For paid advertising, we typically achieve positive ROAS within the first month. Outbound campaigns start generating qualified meetings within 2-3 weeks. AI implementations show efficiency gains within the first quarter."
  },
  {
    question: "Do you work with companies of all sizes?",
    answer: "Yes, we work with companies across all stages - from early-stage startups to late-stage companies and enterprises. Our solutions are tailored to each client's specific needs, growth goals, and current stage. Whether you're looking to establish initial traction or scale existing channels, we have the expertise to help you succeed."
  }
];