export const faqData = [
  {
    question: "What makes your AI talent network unique?",
    answer: "Our network consists of 50,000+ pre-vetted AI specialists from top institutions (MIT, Stanford, CMU) and companies (Google AI, Meta AI, OpenAI). We maintain active relationships with AI researchers, engineers, and leaders through exclusive events and communities, giving us access to passive candidates others can't reach."
  },
  {
    question: "How quickly can you find qualified candidates?",
    answer: "We typically present the first qualified candidates within 7-10 days. For most roles, we complete the entire hiring process within 2-4 weeks. Even for specialized AI positions, we've helped companies build entire teams (10-15 engineers) in 6-8 weeks."
  },
  {
    question: "What's your vetting process for AI talent?",
    answer: "Our technical assessment process includes practical ML system design challenges, research paper discussions, and hands-on coding tests focused on ML/AI implementations. For specialized roles like LLM engineers or ML infrastructure, we have domain experts conduct in-depth technical interviews."
  },
  {
    question: "Do you provide compensation benchmarking?",
    answer: "Yes, we provide detailed compensation insights specific to AI roles across different markets and company stages. Our data comes from thousands of successful placements and active tracking of AI talent markets. We help structure competitive offers that attract top talent."
  },
  {
    question: "What's your success rate and guarantee?",
    answer: "We maintain a 90% offer acceptance rate and 95% retention rate at 1 year. We offer a 90-day replacement guarantee - if a candidate leaves or doesn't meet expectations within this period, we'll find a replacement at no additional cost."
  },
  {
    question: "Can you help scale entire AI teams?",
    answer: "Absolutely. We've helped companies build complete AI teams from scratch - from individual contributors to technical leaders. We create customized hiring plans aligned with your technical roadmap and growth goals, whether you're building a founding AI team or scaling an existing one."
  }
];