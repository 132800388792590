import React from 'react';
import { steps } from './processData';

export function ProcessSection() {
  return (
    <section className="py-24 bg-background-darker">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <div className="inline-block px-4 py-1 bg-primary/10 rounded-full text-primary-light text-sm font-semibold mb-4">
            Our Process
          </div>
          <h2 className="text-4xl font-bold mb-4 text-white">
            Systematic Growth Framework
          </h2>
          <p className="text-xl text-white/80">
            We Partner With 2-3 Companies At A Time For Quality & Impact
          </p>
        </div>
        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
          {steps.map((step, index) => (
            <div
              key={index}
              className="bg-background-dark p-8 rounded-2xl hover:bg-background-dark/80 transition-colors border border-primary/10 hover:border-primary/30 h-full"
            >
              <div className="w-12 h-12 bg-gradient-to-br from-primary to-primary-dark rounded-xl flex items-center justify-center mb-6">
                {React.createElement(step.icon, {
                  className: 'w-6 h-6 text-white',
                })}
              </div>
              <h3 className="text-xl font-bold mb-3 text-white">{step.title}</h3>
              <p className="text-white/80">{step.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}