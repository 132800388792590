import React from 'react';
import { useScrollToCalendar } from '../hooks/useScrollToCalendar';

interface CalendarButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'primary' | 'secondary';
  size?: 'sm' | 'md' | 'lg';
  className?: string;
}

export function CalendarButton({ 
  children, 
  variant = 'primary', 
  size = 'md', 
  className = '',
  ...props 
}: CalendarButtonProps) {
  const scrollToCalendar = useScrollToCalendar();

  return (
    <button
      onClick={scrollToCalendar}
      className={`inline-flex items-center justify-center font-medium rounded-lg transition-all duration-300 ${
        variant === 'primary' 
          ? 'bg-primary text-white hover:bg-primary-dark shadow-lg shadow-primary/25 hover:shadow-xl hover:shadow-primary/30 backdrop-blur-sm' 
          : 'bg-white/10 text-white border border-white/20 hover:bg-white/20 backdrop-blur-sm'
      } ${
        size === 'sm' ? 'px-4 py-2 text-sm' :
        size === 'md' ? 'px-6 py-3 text-base' :
        'px-8 py-4 text-lg tracking-wide'
      } ${className}`}
      {...props}
    >
      {children}
    </button>
  );
}