import React from 'react';
import { Shield, Users, BarChart3 } from 'lucide-react';
import { Button } from './Button';

export function CTASection() {
  const scrollToCalendar = () => {
    const calendarSection = document.querySelector('.calendar-section');
    if (calendarSection) {
      calendarSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <section className="py-20 bg-gradient-to-br from-primary-dark via-background-dark to-background-darker text-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
        <div className="inline-block px-4 py-1 bg-primary/10 rounded-full text-primary-light text-sm font-semibold mb-4">
          READY TO SCALE?
        </div>
        <h2 className="text-4xl font-bold mb-4">
          Turn Your Growth Into a 
          <span className="text-primary"> Science</span>
        </h2>
        <p className="text-xl mb-8 max-w-2xl mx-auto text-text-secondary">
          From early-stage startups to enterprise organizations, we help companies at every stage build systematic, predictable growth engines.
        </p>
        <Button 
          variant="primary" 
          size="lg"
          onClick={scrollToCalendar}
          className="transform hover:scale-105 transition-all duration-300"
        >
          Scale Your Growth
        </Button>
        <div className="mt-12 flex justify-center gap-12">
          <div className="text-center">
            <Shield className="w-12 h-12 text-primary-light opacity-75 mx-auto mb-2" />
            <p className="text-sm text-white/70">Enterprise Ready</p>
          </div>
          <div className="text-center">
            <Users className="w-12 h-12 text-primary-light opacity-75 mx-auto mb-2" />
            <p className="text-sm text-white/70">Elite Network</p>
          </div>
          <div className="text-center">
            <BarChart3 className="w-12 h-12 text-primary-light opacity-75 mx-auto mb-2" />
            <p className="text-sm text-white/70">Proven Results</p>
          </div>
        </div>
      </div>
    </section>
  );
}