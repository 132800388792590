import React from 'react';
import { motion } from 'framer-motion';

interface FloatingElementProps {
  className?: string;
  delay?: number;
  duration?: number;
}

export function FloatingElement({ className = '', delay = 0, duration = 5 }: FloatingElementProps) {
  return (
    <motion.div
      className={`absolute ${className}`}
      animate={{
        y: [0, 20, 0],
        rotate: [0, 5, 0],
        scale: [1, 1.05, 1],
      }}
      transition={{
        duration,
        repeat: Infinity,
        repeatType: "reverse",
        ease: "easeInOut",
        delay,
      }}
    />
  );
}