import React from 'react';
import { motion } from 'framer-motion';
import { Target, Rocket, BarChart3, TrendingUp } from 'lucide-react';
import { steps } from './processData';

const icons = {
  Target,
  Rocket,
  BarChart3,
  TrendingUp
};

export function ProcessSection() {
  return (
    <section className="py-24 bg-background-darker">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <div className="inline-block px-4 py-1 bg-blue-500/10 rounded-full text-blue-400 text-sm font-semibold mb-4">
            THE BLUEPRINT
          </div>
          <h2 className="text-4xl font-bold mb-4 text-white">
            No BS, Just Pure <span className="text-blue-400">RESULTS</span>
          </h2>
          <p className="text-xl text-white/80">
            While Others Play It Safe, We're Playing For Keeps
          </p>
        </div>
        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
          {steps.map((step, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              className="bg-background-dark p-8 rounded-2xl hover:bg-background-dark/80 transition-colors border border-blue-500/10 hover:border-blue-500/30 h-full transform hover:scale-105 transition-all duration-300"
            >
              <div className="w-12 h-12 bg-gradient-to-br from-blue-500 to-blue-600 rounded-xl flex items-center justify-center mb-6">
                {React.createElement(icons[step.icon as keyof typeof icons], {
                  className: 'w-6 h-6 text-white',
                })}
              </div>
              <h3 className="text-xl font-bold mb-3 text-white">{step.title}</h3>
              <p className="text-white/80">{step.description}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
}