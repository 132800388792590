export const caseStudies = [
  {
    metric: '$4.2M',
    subtitle: 'Revenue in 30 Days',
    highlight: 'CRUSHED IT FOR E-COMMERCE BRAND',
    points: [
      'Scaled ad spend from $50K to $500K/month',
      '3.8X ROAS across all channels',
      'Dominated TikTok & Instagram with viral creatives'
    ]
  },
  {
    metric: '78%',
    subtitle: 'Lower CAC',
    highlight: 'DISRUPTED B2B SAAS MARKET',
    points: [
      'Crushed competitor CPAs by 50%+',
      'AI-powered creative testing machine',
      'Scaled to $250K/day profitably'
    ]
  },
  {
    metric: '12X',
    subtitle: 'Revenue Growth',
    highlight: 'EXPLOSIVE D2C BRAND SCALING',
    points: [
      'From $300K to $3.6M monthly revenue',
      'Maintained 2.5X+ ROAS while scaling',
      'Dominated 6 platforms simultaneously'
    ]
  },
  {
    metric: '$1.2M',
    subtitle: 'MRR Growth',
    highlight: 'B2B SAAS TAKEOVER',
    points: [
      'Crushed enterprise lead gen goals',
      'Cut sales cycle by 45% with retargeting',
      'Scaled from 0 to 1,000+ demos/month'
    ]
  }
];