import React, { useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X } from 'lucide-react';
import { useCalendarModal } from '../hooks/useCalendarModal';

export function CalendarModal() {
  const { isOpen, close } = useCalendarModal();

  useEffect(() => {
    if (isOpen) {
      // Load TidyCal script
      const script = document.createElement('script');
      script.src = 'https://asset-tidycal.b-cdn.net/js/embed.js';
      script.async = true;
      document.body.appendChild(script);

      // Cleanup
      return () => {
        document.body.removeChild(script);
      };
    }
  }, [isOpen]);

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black/50 backdrop-blur-sm"
          onClick={close}
        >
          <motion.div
            initial={{ scale: 0.95, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.95, opacity: 0 }}
            className="relative w-full max-w-xl bg-white rounded-2xl shadow-2xl"
            onClick={e => e.stopPropagation()}
          >
            <button
              onClick={close}
              className="absolute top-4 right-4 p-2 text-gray-500 hover:text-gray-700 transition-colors z-10"
            >
              <X className="w-6 h-6" />
            </button>
            <div className="p-6 max-h-[80vh] overflow-y-auto">
              <div 
                className="tidycal-embed" 
                data-path="1y56wy3/15-minute-meeting"
                style={{ 
                  width: '100%',
                  height: '600px',
                  border: 'none',
                  overflow: 'auto'
                }}
              ></div>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}