import React from 'react';
import { motion } from 'framer-motion';
import { CaseStudyCard } from './CaseStudies/CaseStudyCard';
import { caseStudies } from './CaseStudies/caseStudyData';

export function CaseStudiesSection() {
  return (
    <section className="py-20 bg-background-darker relative overflow-hidden">
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-gradient-to-b from-primary/5 via-transparent to-transparent opacity-50" />
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
        <div className="text-center mb-16">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
          >
            <div className="inline-block px-4 py-1 bg-primary/10 rounded-full text-primary-light text-sm font-semibold mb-4">
              PROVEN RESULTS
            </div>
            <h2 className="text-4xl font-bold mb-4 text-white">
              Doubling Down on What <span className="text-primary">Works</span>,<br />
              <span className="text-primary">Scaling</span> What Wins
            </h2>
            <p className="text-xl text-white/80 mt-4 max-w-2xl mx-auto">
              Real outcomes from our systematic approach to growth
            </p>
          </motion.div>
        </div>

        <div className="grid md:grid-cols-2 gap-6">
          {caseStudies.map((study, index) => (
            <CaseStudyCard key={index} {...study} index={index} />
          ))}
        </div>
      </div>
    </section>
  );
}