import React from 'react';
import { motion } from 'framer-motion';
import { Search, Filter, Database, Users } from 'lucide-react';

const steps = [
  {
    icon: Search,
    title: 'Find Your Ideal Customers',
    description: 'AI-powered prospecting that identifies companies matching your ideal customer profile.'
  },
  {
    icon: Filter,
    title: 'Enrich & Validate',
    description: 'Automatically enrich prospect data and validate contact information for higher deliverability.'
  },
  {
    icon: Database,
    title: 'Build Target Lists',
    description: 'Create segmented prospect lists based on industry, company size, technology stack, and more.'
  },
  {
    icon: Users,
    title: 'Map Key Stakeholders',
    description: 'Identify and connect with all relevant decision-makers within target accounts.'
  }
];

export function ProspectingSection() {
  return (
    <section className="py-20 bg-background-dark relative overflow-hidden">
      <div className="absolute inset-0 bg-gradient-to-b from-background-darker to-transparent opacity-50" />
      
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <div className="inline-block px-4 py-1 bg-emerald-500/10 rounded-full text-emerald-400 text-sm font-semibold mb-4">
            SMART PROSPECTING
          </div>
          <h2 className="text-4xl font-bold text-white mb-6">
            Find Your Perfect-Fit Prospects
          </h2>
          <p className="text-lg text-white/80 max-w-2xl mx-auto">
            Stop wasting time on unqualified leads. Our AI helps you identify and connect with 
            prospects who are most likely to convert.
          </p>
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
          {steps.map((step, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              className="relative group"
            >
              <div className="absolute inset-0 bg-gradient-to-b from-white/5 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-2xl" />
              <div className="relative p-8 bg-background-darker rounded-2xl border border-white/10 group-hover:border-emerald-500/30 transition-colors text-center">
                <div className="w-12 h-12 mx-auto text-emerald-400 bg-white/5 rounded-xl flex items-center justify-center mb-4 group-hover:scale-110 transition-transform duration-300">
                  {React.createElement(step.icon, {
                    className: "w-6 h-6",
                  })}
                </div>
                <h3 className="text-xl font-bold mb-3 text-white group-hover:text-emerald-400 transition-colors">
                  {step.title}
                </h3>
                <p className="text-white/70">{step.description}</p>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
}