import React from 'react';
import { Gem } from 'lucide-react';

interface LogoProps {
  className?: string;
}

export function Logo({ className = '' }: LogoProps) {
  return (
    <div className={`flex items-center gap-2 ${className}`}>
      <Gem className="w-8 h-8 text-amber-400" />
      <span className="text-xl font-bold text-white">aiGrowthGroup.com</span>
    </div>
  );
}