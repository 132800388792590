export const steps = [
  {
    title: 'CRUSH IT with Data',
    description: 'Deep dive into your numbers, spy on competitors, and build a knockout strategy that\'s gonna blow up your ROAS.',
    icon: 'Target'
  },
  {
    title: 'DOMINATE Every Platform',
    description: 'Deploy across Meta, Google, TikTok - wherever your customers are, we\'re gonna be there first and loudest.',
    icon: 'Rocket'
  },
  {
    title: 'SCALE Like Crazy',
    description: 'Triple down on what works. Our AI spots winners faster than humans ever could. When we find gold, we go ALL IN.',
    icon: 'BarChart3'
  },
  {
    title: 'COMPOUND Your Wins',
    description: 'Take those winning campaigns and turn them into unstoppable machines. This is where the REAL money gets made.',
    icon: 'TrendingUp'
  }
];