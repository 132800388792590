export const faqData = [
  {
    question: "What's your approach to managing large ad budgets?",
    answer: "We use a systematic, data-driven approach combining AI-powered optimization and human expertise. For budgets over $100K/month, we implement custom attribution modeling, advanced audience segmentation, and real-time bid adjustments. Our platform monitors campaigns 24/7 with automated alerts for any performance anomalies."
  },
  {
    question: "How do you measure and optimize ROAS?",
    answer: "We track ROAS through multi-touch attribution, considering both direct and assisted conversions. Our AI models analyze thousands of data points to optimize bid strategies, creative performance, and audience targeting. We typically achieve a 3-4x ROAS within the first 90 days, with continuous improvement through systematic testing."
  },
  {
    question: "What makes your creative testing process different?",
    answer: "Our creative testing framework combines AI-powered analysis of 50,000+ top-performing ads with systematic A/B testing. We test multiple variables simultaneously (copy, visuals, formats) using multi-armed bandit algorithms, allowing us to identify winning combinations 3x faster than traditional methods."
  },
  {
    question: "How do you handle privacy changes and iOS updates?",
    answer: "We've developed advanced measurement solutions that combine first-party data, modeled conversions, and platform signals to maintain accurate tracking post-iOS 14. Our system uses probabilistic attribution and predictive modeling to fill data gaps, ensuring reliable performance measurement across all platforms."
  },
  {
    question: "What's included in your reporting and how often do we meet?",
    answer: "You get access to a real-time dashboard showing ROAS, CAC, and other key metrics across all channels. We provide weekly performance summaries, bi-weekly strategy calls, and monthly deep-dive sessions covering performance analysis, competitive insights, and growth opportunities. Our average client reduces CAC by 40% within 6 months."
  }
];