import React from 'react';
import { motion } from 'framer-motion';
import { Button } from '../../../../components/Button';
import { RotatingText } from '../../../../components/RotatingText';

const rotatingWords = [
  'Meta Ads',
  'Google Ads',
  'TikTok Ads',
  'LinkedIn Ads',
  'YouTube Ads'
];

export function PaidHero() {
  const scrollToCalendar = () => {
    const calendarSection = document.querySelector('#calendar-section');
    if (calendarSection) {
      calendarSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <section className="relative min-h-[85vh] bg-background-darker overflow-hidden">
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-gradient-radial from-blue-500/5 via-transparent to-transparent opacity-80" />
        <div className="absolute inset-0 bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-blue-500/10 via-transparent to-transparent blur-xl" />
      </div>

      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-32">
        <div className="max-w-4xl mx-auto text-center">
          <motion.div
            className="text-6xl font-bold mb-8 text-white leading-tight"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            <h1>
              We Help <span className="text-blue-400">Growth-Obsessed</span><br />
              Companies Scale With
            </h1>
            <div className="mt-4 flex justify-center">
              <RotatingText words={rotatingWords} />
            </div>
          </motion.div>

          <motion.div
            className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-12"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.4 }}
          >
            <div className="bg-background-dark/50 backdrop-blur-sm rounded-xl p-6">
              <div className="text-3xl font-bold text-blue-400 mb-2">$500M+</div>
              <p className="text-white/80">Ad Spend Managed</p>
            </div>
            <div className="bg-background-dark/50 backdrop-blur-sm rounded-xl p-6">
              <div className="text-3xl font-bold text-blue-400 mb-2">4.2x</div>
              <p className="text-white/80">Average ROAS</p>
            </div>
            <div className="bg-background-dark/50 backdrop-blur-sm rounded-xl p-6">
              <div className="text-3xl font-bold text-blue-400 mb-2">-35%</div>
              <p className="text-white/80">CAC Reduction</p>
            </div>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.6 }}
            className="mt-12"
          >
            <Button 
              variant="primary" 
              size="lg"
              className="bg-blue-500 hover:bg-blue-600 text-white font-semibold transform hover:scale-105 transition-all duration-300 shadow-[0_0_30px_rgba(59,130,246,0.3)] hover:shadow-[0_0_50px_rgba(59,130,246,0.5)]"
              onClick={scrollToCalendar}
            >
              Scale Your Ad Campaigns
            </Button>
          </motion.div>
        </div>
      </div>
    </section>
  );
}