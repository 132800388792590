import React from 'react';
import { Link } from 'react-router-dom';
import { Linkedin, Twitter, Mail } from 'lucide-react';
import { Logo } from '../Navbar/Logo';

export function Footer() {
  const navigation = {
    services: [
      { name: 'Scale Leads through DemandGen', href: '/paid-advertising' },
      { name: 'Build Your Team', href: '/hire-team' },
      { name: 'Outbound Engine', href: '/inspo' }
    ],
    company: [
      { name: 'Careers', href: 'https://airtable.com/appPs2xXguaNEbTWq/shrLDy41JSpLKfIT0', external: true }
    ],
    social: [
      { name: 'LinkedIn', icon: Linkedin, href: 'https://www.linkedin.com/company/aigrowthgroup' },
      { name: 'Twitter', icon: Twitter, href: 'https://twitter.com' },
      { name: 'Email', icon: Mail, href: 'mailto:matthew@aigrowthgroup.com' }
    ]
  };

  return (
    <footer className="bg-background-darker border-t border-white/10">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="grid grid-cols-2 md:grid-cols-3 gap-8 mb-12">
          <div className="col-span-2 md:col-span-1">
            <Link to="/" className="inline-block mb-6">
              <Logo />
            </Link>
            <p className="text-white/70 text-sm">
              Helping companies scale through data-driven growth strategies and elite talent acquisition.
            </p>
          </div>
          
          <div>
            <h3 className="text-white font-semibold mb-4">Services</h3>
            <ul className="space-y-3">
              {navigation.services.map((item) => (
                <li key={item.name}>
                  <Link 
                    to={item.href}
                    className="text-white/70 hover:text-white transition-colors text-sm"
                  >
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          
          <div>
            <h3 className="text-white font-semibold mb-4">Company</h3>
            <ul className="space-y-3">
              {navigation.company.map((item) => (
                <li key={item.name}>
                  {item.external ? (
                    <a
                      href={item.href}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-white/70 hover:text-white transition-colors text-sm"
                    >
                      {item.name}
                    </a>
                  ) : (
                    <Link 
                      to={item.href}
                      className="text-white/70 hover:text-white transition-colors text-sm"
                    >
                      {item.name}
                    </Link>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
        
        <div className="flex flex-col md:flex-row justify-between items-center pt-8 border-t border-white/10">
          <p className="text-white/70 text-sm mb-4 md:mb-0">
            © {new Date().getFullYear()} AI Growth Group LLC. All rights reserved.
          </p>
          
          <div className="flex space-x-6">
            {navigation.social.map((item) => (
              <a
                key={item.name}
                href={item.href}
                className="text-white/70 hover:text-white transition-colors"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="sr-only">{item.name}</span>
                <item.icon className="h-5 w-5" />
              </a>
            ))}
          </div>
        </div>
      </div>
    </footer>
  );
}