import React from 'react';
import { CompanyLogos } from './CompanyLogos';
import { ValueProposition } from './ValueProposition';
import { TalentNetwork } from './TalentNetwork';
import { SuccessStories } from './SuccessStories';

export function WhyChooseUsSection() {
  return (
    <>
      <CompanyLogos />
      <ValueProposition />
      <TalentNetwork />
      <SuccessStories />
    </>
  );
}