import React from 'react';
import { Layout } from '../components/Layout';
import { HeroSection } from '../components/HeroSection';
import { ServicesSection } from '../components/ServicesSection';
import { ProcessSection } from '../components/ProcessSection/ProcessSection';
import { CaseStudiesSection } from '../components/CaseStudiesSection';
import { CalendarSection } from '../components/CalendarSection';
import { FAQSection } from '../components/FAQ/FAQSection';
import { CTASection } from '../components/CTASection';

const HomePage = () => {
  return (
    <Layout>
      <HeroSection />
      <ServicesSection />
      <ProcessSection />
      <CaseStudiesSection />
      <CalendarSection />
      <FAQSection />
      <CTASection />
    </Layout>
  );
}

export default HomePage;