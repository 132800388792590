import { create } from 'zustand';

interface CalendarModalStore {
  isOpen: boolean;
  open: () => void;
  close: () => void;
}

export const useCalendarModal = create<CalendarModalStore>((set) => ({
  isOpen: false,
  open: () => set({ isOpen: true }),
  close: () => set({ isOpen: false }),
}));