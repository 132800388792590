import React from 'react';
import { motion, useAnimation } from 'framer-motion';

const techStacks = [
  {
    category: 'Sales Engagement',
    tools: [
      { name: 'Outreach.io', color: '#FF6B6B' },
      { name: 'SalesLoft', color: '#4ECDC4' },
      { name: 'Reply.io', color: '#45B7D1' },
      { name: 'Mailshake', color: '#96CEB4' },
      { name: 'Lemlist', color: '#FF9F1C' }
    ]
  },
  {
    category: 'Data & Enrichment',
    tools: [
      { name: 'ZoomInfo', color: '#6C5CE7' },
      { name: 'Apollo', color: '#A8E6CF' },
      { name: 'Clearbit', color: '#FF8B94' },
      { name: 'Crunchbase', color: '#3D84A8' },
      { name: 'Hunter.io', color: '#46237A' }
    ]
  },
  {
    category: 'CRM & Analytics',
    tools: [
      { name: 'Salesforce', color: '#00A1E0' },
      { name: 'HubSpot', color: '#FF7A59' },
      { name: 'Pipedrive', color: '#26292C' },
      { name: 'Close.com', color: '#2D3142' },
      { name: 'Copper', color: '#00B388' }
    ]
  },
  {
    category: 'Automation',
    tools: [
      { name: 'Zapier', color: '#FF4A00' },
      { name: 'Make.com', color: '#00A4EF' },
      { name: 'Clay', color: '#FFB400' },
      { name: 'n8n', color: '#6933FF' },
      { name: 'Phantombuster', color: '#24292E' }
    ]
  }
];

const FloatingLogo = ({ tool, index }: { tool: { name: string; color: string }; index: number }) => {
  const controls = useAnimation();

  const randomFloat = () => ({
    x: Math.random() * 40 - 20,
    y: Math.random() * 40 - 20,
    rotate: Math.random() * 20 - 10,
    transition: {
      duration: 3 + Math.random() * 2,
      ease: "easeInOut",
      repeat: Infinity,
      repeatType: "reverse"
    }
  });

  React.useEffect(() => {
    controls.start(randomFloat());
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{
        opacity: 1,
        scale: 1,
        transition: { delay: index * 0.1 }
      }}
      whileHover={{
        scale: 1.2,
        transition: { duration: 0.2 }
      }}
      className="relative group cursor-pointer"
    >
      <motion.div
        animate={controls}
        className="relative z-10"
      >
        <div
          className="px-4 py-2 rounded-lg font-semibold text-white backdrop-blur-sm"
          style={{
            backgroundColor: `${tool.color}33`,
            border: `1px solid ${tool.color}66`
          }}
        >
          {tool.name}
        </div>
      </motion.div>
    </motion.div>
  );
};

export function TechStackShowcase() {
  return (
    <section className="py-32 bg-background-darker relative overflow-hidden">
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-gradient-to-b from-emerald-500/5 via-transparent to-transparent opacity-50" />
      </div>

      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-center mb-16"
        >
          <div className="inline-block px-4 py-1 bg-emerald-500/10 rounded-full text-emerald-400 text-sm font-semibold mb-4">
            YOUR ENTIRE STACK
          </div>
          <h2 className="text-4xl font-bold text-white mb-6">
            We Work Across Your
            <br />
            <span className="text-emerald-400">Sales & Marketing Stack</span>
          </h2>
          <p className="text-lg text-white/80 max-w-3xl mx-auto">
            Deep expertise across all major sales and marketing tools. We help you get the most out of your existing stack or build something new.
          </p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12">
          {techStacks.map((category, categoryIndex) => (
            <motion.div
              key={category.category}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: categoryIndex * 0.2 }}
              className="relative"
            >
              <div className="absolute inset-0 bg-gradient-to-b from-emerald-500/5 to-transparent rounded-2xl opacity-50" />
              <div className="relative space-y-8">
                <h3 className="text-lg font-semibold text-white text-center mb-6">
                  {category.category}
                </h3>
                <div className="grid gap-4">
                  {category.tools.map((tool, index) => (
                    <FloatingLogo
                      key={tool.name}
                      tool={tool}
                      index={index}
                    />
                  ))}
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
}