export const caseStudies = [
  {
    metric: '$1.5M',
    subtitle: 'Monthly Pipeline Growth',
    highlight: 'OUTBOUND ENGINE SUCCESS',
    points: [
      'AI-powered prospect targeting',
      'Multi-channel orchestration',
      'Automated follow-up sequences'
    ]
  },
  {
    metric: '60%',
    subtitle: 'Reduction in CAC',
    highlight: 'DEMAND GENERATION MASTERY',
    points: [
      'Lookalike audience optimization',
      'Cross-channel retargeting',
      'SEO-driven organic growth'
    ]
  },
  {
    metric: '92%',
    subtitle: 'Offer Acceptance Rate',
    highlight: 'ELITE TALENT ACQUISITION',
    points: [
      'AI-powered candidate matching',
      'Systematic interview process',
      'Competitive offer strategy'
    ]
  },
  {
    metric: '$2.5M',
    subtitle: 'Revenue Increase',
    highlight: 'SYSTEMATIC GROWTH ENGINE',
    points: [
      'Integrated growth strategy',
      'Multi-channel execution',
      'Continuous optimization'
    ]
  }
];