import React from 'react';
import { Layout } from '../../components/Layout';
import { HireTeamHero } from './components/HireTeamHero';
import { ExpertiseSection } from './components/ExpertiseSection';
import { WhyChooseUsSection } from './components/WhyChooseUs/WhyChooseUsSection';
import { ProcessSection } from './components/ProcessSection';
import { FAQSection } from './components/FAQ/FAQSection';
import { CTASection } from '../../components/CTASection';

export default function HireTeamPage() {
  return (
    <Layout>
      <HireTeamHero />
      <ExpertiseSection />
      <WhyChooseUsSection />
      <ProcessSection />
      <FAQSection />
      <CTASection />
    </Layout>
  );
}