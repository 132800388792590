import React from 'react';
import { motion } from 'framer-motion';
import { Button } from './Button';
import { FloatingElement } from './FloatingElement';
import { RotatingText } from './RotatingText';

const rotatingWords = [
  'Meta Ads',
  'Google Ads',
  'TikTok Ads',
  'LinkedIn Ads',
  'YouTube Ads'
];

export function HeroSection() {
  const scrollToCalendar = () => {
    const calendarSection = document.querySelector('.calendar-section');
    if (calendarSection) {
      calendarSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <section className="relative min-h-[85vh] bg-background-darker overflow-hidden">
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-gradient-radial from-primary/5 via-transparent to-transparent opacity-80" />
        <div className="absolute inset-0 bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-primary/10 via-transparent to-transparent blur-xl" />
      </div>
      
      {/* Floating elements */}
      <FloatingElement 
        className="top-20 left-20 w-32 h-32 bg-gradient-to-br from-blue-500/20 to-blue-600/20 rounded-2xl blur-sm"
        delay={0}
      />
      <FloatingElement 
        className="bottom-20 right-20 w-40 h-40 bg-gradient-to-br from-amber-500/20 to-amber-600/20 rounded-2xl blur-sm"
        delay={1}
      />
      <FloatingElement 
        className="top-1/3 right-1/4 w-24 h-24 bg-gradient-to-br from-primary/20 to-primary-dark/20 rounded-2xl blur-sm"
        delay={2}
      />
      
      {/* Content */}
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-32">
        <div className="max-w-4xl mx-auto text-center">
          <motion.div 
            className="text-6xl font-bold mb-8 text-white leading-tight"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            <h1>
              We Help <span className="text-amber-400">Growth-Obsessed</span><br />
              Companies Scale With
            </h1>
            <div className="mt-4 flex justify-center">
              <RotatingText words={rotatingWords} />
            </div>
          </motion.div>
          <motion.p 
            className="text-2xl mb-12 text-white/80 font-light tracking-wide"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
          >
            $500M+ in pipeline created. 100+ companies scaled.<br />
            From strategic campaigns to tailored execution, we create systems that attract, engage, and scale top talent while driving predictable demand.
          </motion.p>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.4 }}
          >
            <Button 
              variant="primary" 
              size="lg" 
              className="bg-amber-400 hover:bg-amber-500 text-black font-semibold transform hover:scale-105 transition-all duration-300 shadow-[0_0_30px_rgba(251,191,36,0.3)] hover:shadow-[0_0_50px_rgba(251,191,36,0.5)]"
              onClick={scrollToCalendar}
            >
              See if you're a fit
            </Button>
          </motion.div>
        </div>
      </div>
    </section>
  );
}