import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { ScrollToTop } from './components/ScrollToTop';
import { CalendarModal } from './components/CalendarModal';
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import PaidAdvertisingPage from './pages/services/paid-advertising';
import HireTeamPage from './pages/hire-team';
import InspoPage from './pages/InspoPage';

const App = () => {
  return (
    <>
      <ScrollToTop />
      <CalendarModal />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/paid-advertising" element={<PaidAdvertisingPage />} />
        <Route path="/hire-team" element={<HireTeamPage />} />
        <Route path="/inspo" element={<InspoPage />} />
      </Routes>
    </>
  );
}

export default App;