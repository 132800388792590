import React from 'react';
import { motion } from 'framer-motion';
import { DollarSign } from 'lucide-react';

interface CaseStudyCardProps {
  metric: string;
  subtitle: string;
  highlight: string;
  points: string[];
  index: number;
}

export function CaseStudyCard({ metric, subtitle, highlight, points, index }: CaseStudyCardProps) {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.5, delay: index * 0.1 }}
      className="bg-background-dark p-8 rounded-2xl border border-primary/10 hover:border-primary/30 transition-all duration-300 relative group"
    >
      <div className="absolute top-6 right-6 w-12 h-12 bg-primary/10 rounded-xl flex items-center justify-center opacity-50 group-hover:opacity-100 transition-opacity">
        <DollarSign className="w-6 h-6 text-primary" />
      </div>
      
      <div className="space-y-4">
        <div>
          <h3 className="text-4xl font-bold text-primary mb-1">{metric}</h3>
          <p className="text-xl text-white/80">{subtitle}</p>
        </div>
        
        <div className="py-4">
          <p className="text-sm font-semibold text-primary/90 tracking-wider">
            {highlight}
          </p>
        </div>
        
        <ul className="space-y-2">
          {points.map((point, idx) => (
            <li key={idx} className="flex items-center gap-2 text-white/70">
              <div className="w-1.5 h-1.5 bg-primary rounded-full" />
              <span>{point}</span>
            </li>
          ))}
        </ul>
      </div>
    </motion.div>
  );
}